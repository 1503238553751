<script setup lang="ts">
const { navigation, page } = useContent()
const { user } = await useAuth()

const path = computed(() => {
  if (page.value?._path) {
    return page.value?._path
  } else if (process.client) {
    return window.location.pathname
  }
})

const links = computed(() => {
  return (navigation.value || []).filter((item) => {
    item.isMobile = true
    const isPathDevelopers = path.value?.includes('/developers/')
    const isItemPathDevelopers = item._path?.includes('/developers')
    if (isPathDevelopers) {
      return isItemPathDevelopers
    } else {
      // Hide 'Blog' and 'News', 'Bootcamps' from mobile nav
      if (item.title === "Blog" || item.title === "News" || item.title === "Bootcamp") return false
      return !isItemPathDevelopers;
    }
  }).sort((a, b) => (a.hasOwnProperty('children') ? -1 : 1));
})

const mobileNav = {
  products: {
    title: 'Products', children: [{
      title: 'Live Data Framework',
      _path: '/developers/user-guide/introduction/welcome',
      icon: 'heroicons:cpu-chip-20-solid'
    }, {
      title: 'AI Pipelines',
      _path: '/developers/templates?q=llm',
      icon: 'heroicons:cog-20-solid'
    }, {
      title: 'Connectors',
      _path: '/developers/user-guide/connect/pathway-connectors',
      icon: 'heroicons:arrow-down-on-square-stack-20-solid'
    }, {
      title: 'Local deployment',
      _path: '/developers/user-guide/deployment/docker-deployment',
      icon: 'heroicons:computer-desktop-solid'
    }, {
      title: 'Cloud deployment',
      _path: '/developers/user-guide/deployment/cloud-deployment',
      icon: 'heroicons:cloud-solid'
    }]
  },
  solutions: {
    title: 'Solutions', _path: '/solutions', children: [{
      title: 'Document Answering',
      _path: '/solutions/document-answering'
    }, {
      title: 'RAG Slides Search',
      _path: '/solutions/slides-ai-search'
    }, {
      title: 'AI-enabled vehicles & eSports',
      _path: '/solutions/ai-enabled-vehicles-n-esports'
    }, {
      title: 'Logistics',
      _path: '/solutions/logistics'
    }, {
      title: 'Contract Management with AI',
      _path: '/solutions/ai-contract-management'
    }, {
      title: 'Success Stories',
      _path: '/success-stories',
      icon: 'heroicons:trophy-16-solid'
    }]
  },
  news: {
    title: 'News',
    _path: '/blog',
  },
  learn: {
    title: 'Learn', _path: 'learn', id: 'learn-1', children: [{
      title: 'Free LLM Bootcamp',
      _path: '/bootcamps/rag-and-llms',
      icon: 'heroicons:academic-cap-solid'
    }, {
      title: 'Help and troubleshooting',
      _path: '/developers/user-guide/development/get-help',
      icon: 'heroicons:wrench-screwdriver-16-solid'
    }, {
      title: 'Developer blog',
      _path: '/blog',
      icon: 'heroicons:code-bracket-square-16-solid'
    }]
  },
  about: {
    title: 'About', _path: 'about', children: [{
      title: 'Team',
      _path: '/team',
      icon: 'heroicons:user-group-16-solid'
    }, {
      title: 'Events',
      _path: '/events',
      icon: 'heroicons:calendar-date-range-16-solid'
    }, {
      title: 'Product changelog',
      _path: '/developers/user-guide/development/changelog',
      icon: 'heroicons:archive-box-solid'
    }]
  },
  GetStarted: {
    title: 'Get Started', _path: 'get-started', children: [{
      title: 'Developer documentation',
      _path: '/developers/user-guide/introduction/welcome',
      icon: 'heroicons:book-open-solid'
    }, {
      title: 'Installation Instructions',
      _path: '/developers/user-guide/introduction/installation',
      icon: 'heroicons:document-arrow-down-16-solid'
    }, {
      title: 'App Templates',
      _path: '/developers/templates',
      icon: 'heroicons:bolt-20-solid'
    }]
  }
}

/* Separate developers navigation */
const route = useRoute()
const { visible, open, close } = useMenu()
watch(() => route.fullPath, async (newRoute, oldRoute) => {
  if (!path.value?.includes('/developers/')) {
    // links.value.splice(3, 0, { title: 'Blog', _path: '/blog' })
    links.value.splice(0, links.value.length)
    links.value.push(mobileNav['products'], mobileNav['solutions'], mobileNav['news'], mobileNav['learn'], mobileNav['about'], mobileNav['GetStarted'])

    // links.value.splice(1, 0, mobileNav['products'])
    // links.value.splice(2, 0, mobileNav['learn'])
    // links.value.splice(5, 0, { title: 'App Templates', _path: '/developers/templates', navigation: { icon: "heroicons:bolt-20-solid" } })

  } else {
    links.value.splice(0, 0, { title: 'Homepage', _path: '/', navigation: { icon: "heroicons:home" }, children: [{ title: "Homepage", _path: "/" }] })
  }
}, { deep: true, immediate: true })

/* Watch navigation toggle */
watch(visible, v => (v ? open() : close()))
</script>

<template>
  <button class="relative z-10 u-text-gray-300 hover:u-text-gray-500 rounded-xl hamburger"
    :class="{ 'nav-develospers': path?.startsWith('/developers') }" aria-label="Menu" @click="open">
    <Icon name="heroicons-outline:menu" class="w-6 h-6 u-text-gray-300 hover:u-text-gray-500" aria-hidden="”true”" />
  </button>

  <!-- eslint-disable-next-line vue/no-multiple-template-root -->
  <teleport to="body">
    <div v-if="visible"
      class="fixed inset-0 z-50 flex items-start overflow-y-auto bg-opacity-50 backdrop-blur dark:bg-opacity-50 lg:hidden"
      @click="close">
      <div class="w-full max-w-xs min-h-full px-4 pb-0 shadow bg-white sm:px-6 flex flex-col justify-center"
        @click.stop>
        <NuxtLink to="/">
          <Logo class="mt-8 pw-text-black" />
        </NuxtLink>
        <DocsAsideTree :links="links" :isMobile="true" class="mt-16 mobile-nav" />
        <div class="flex items-center justify-between gap-2 border-b border-transparent h-header mt-auto">
          <div class="flex items-center gap-4">
            <SocialIcons />
            <div v-if="!user?.provider">
              <NuxtLink to="/get-license"
                class="flex items-center gap-2 whitespace-nowrap shadow-sm p-2 rounded-md text-sm border border-gray-300 transition hover:border-gray-500 font-medium hover:bg-gray-100">
                Start for free
              </NuxtLink>
            </div>
            <button class="relative z-10 u-text-gray-500 hover:u-text-gray-700 rounded-xl" aria-label="Menu"
              @click="close">
              <Icon name="heroicons-outline:x" class="w-6 h-6 u-text-gray-500 hover:u-text-gray-700"
                aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<style>
.mobile-nav a,
.mobile-nav button {
  font-size: 1.2rem;
  margin: 0.5rem 0;
  text-align: left;
}

.mobile-nav button {
  font-weight: 500;
}

.hamburger {
  display: block;
  margin-left: auto;
  margin-right: 1rem;

  @media (min-width: 1024px) {
    display: none;
  }
}

.nav-develospers.hamburger {
  display: block;

  @media (min-width: 1024px) {
    display: none;
  }
}
</style>
